﻿@import 'mixins';
@import 'variables';

/*Prevent the page from "jumping" when loading*/
body {
    overflow-y: scroll;
}

/*Bootstrap fixes*/
/*.popover-content,
.cart-small-popover * {
    box-sizing: content-box;
}*/

.container-2016 {
    @include bootstrapContainer();
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 5px;

    @include fromSizeSM {
        margin-bottom: 0;
    }
}

.article-image {
    width: 100%;
    height: auto;
}

.ColumnCenterWithSidebarLeft .container,
#PageColumnLeft .container,
#PageColumnRight .container,
.ColumnCenterWithSidebarLeft .container-2016 {
    width: 100%;
}

#PageColumnLeft {
    padding-right: 20px;
    padding-bottom: 10px;
}

#PageColumnRight {
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
}

#PageColumnLeft, #PageColumnRight {
    width: 25%;
    float: left;
}

.ColumnCenterWithSidebarLeft {
    width: 100%;

    @include fromSizeSM {
        width: 75%;
        float: left;
    }
}

.fullview.body-department {

    @include fromSizeMD {
        .ColumnCenterWithSidebarLeft {
            width: calc(100% - 250px);
            padding-right: 15px;
            padding-left: 15px;
        }

        .container.breadcrumb-inner {
            width: 100%;
        }

        .ProductMenu {
            float: none;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    /* Grid */
    .container.main-inner {
        @include fromSizeSM() {
            width: 100%;
        }
    }

    .ProductListCellsContainer .OuterPanel .clear {
        clear: none;
    }
}

.body-product-info.fullview .container.breadcrumb-inner {

    @include fromSizeSM() {
        width: 750px;
        padding-left: 15px;
    }

    @include fromSizeMD() {
        width: 970px;
        padding-left: 15px;
    }

    @include fromSizeLG() {
        width: 1170px;
        padding-left: 15px;
    }
}






.search-result-page .PageContainerBorder {
    @include fromSizeSM {
        padding-top: 20px;
    }
}



/*Ajax update icon START*/
.UpdateControlModal {
    height: 100%;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9999999;
    display: none;
}

.UpdateControlModalContent {
    background-attachment: scroll;
    background-color: #000000;
    background-image: url('/css/Demonstrare2016/images/ajax_updater.gif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 46px 46px;
    border: 0 solid #333333;
    border-radius: 36px;
    color: #696969;
    height: 44px;
    left: 50%;
    opacity: 0.4;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 45%;
    width: 44px;
}

.clear {
    clear: both;
}

/* Breadcrumb */
#PageColumnCenter .BreadCrumb {
    display: none;
}

.breadcrumb-content {
    width: 100%;
}

.BreadCrumb {
    padding: 5px 0 10px 5px;

    a {
        color: $textColor;
    }

    .BreadCrumbTitle {
        display: none;
    }

    span {
        margin: 0 -6px;
    }

    @include fromSizeSM() {
        padding: 10px 0;

        .BreadCrumbTitle {
            display: inline;
        }

        span {
            margin: 0;
        }
    }
}

.OriginalUrlContainer {
    word-break: break-word;
    overflow: hidden;
    background: white;
}


.UpdateControlModalContent2 {
    font-family: "multicase-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    animation: spin 2s infinite linear;
    z-index: 99999;
    font-size: 3em;
    height: auto;
    top: 45%;
    position: relative;
    color: #333;
}

.UpdateControlModalContent2:before {
    content: "\f110";
}


input[type="text"], input[type="password"], input[type="date"],
input[type="datetime"], input[type="datetime-local"], input[type="month"],
input[type="week"], input[type="email"], input[type="number"],
input[type="search"], input[type="tel"], input[type="time"],
input[type="url"], textarea {
    font-size: 16px;
}

.ie9 {
    a[href]:after {
        content: "" !important;
    }
}

.BaloonLayerStyle {
    background-color: #ffffff;
    border: 2px solid #d8d6ce;
    padding: 5px;
    position: absolute;
    visibility: hidden;
    z-index: 100;
    text-align: left;
    display: none;
}

/*Attribute icons*/
.WebPubElement .att-icons-container {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
}

.ProductInfo .att-icons-container {
    position: absolute;
    top: 45px;
    right: 6px;
    z-index: 1;
}

.att-icon {
    background-size: cover;
    background-repeat: no-repeat;
    float: right;
    clear: both;
}


/*Custom background image activated*/
.custom-bg-active {

    .container-2016,
    .headroom .container,
    .PageContainerBorder .container,
    .main-container .container,
    .breadcrumb-container .container,
    .CustomerCenterContainer,
    &.body-cart #PageColumnCenter {
        background: white;
        padding-left: 25px;
        padding-right: 25px;
    }

    .ProductInfo .section-content .container {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}
