﻿@import 'variables';
@import 'mixins';

/* Header */
/*Header mobile*/
header {
    z-index: 105; /* Bug fix: popup show small cart over */
    position: relative;
    background-color: #fff;

    &.headroom {
        @include fromSizeSM {
            margin-bottom: 20px;
        }

        .btn-default {
            background-color: #fff;
        }
    }
}


@include toSizeSM {
    .headroom {
        transition: transform 0.25s ease-in-out;
        will-change: transform;

        &--pinned {
            transform: translateY(0);
        }

        &--unpinned {
            transform: translateY(-100%);
        }

        &.animated {
            animation-duration: 0.5s;
        }
    }

    .header--fixed {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
    }

    .m-header-dock .container {
        background-color: #fff;
    }

    .content-special-offers {
        display: none; /* TODO: temporarily removed on mobile */
    }
}
/* InstantSearchD4V2 */
.InstantSearch {
        .prodDesc {
            width: 50%;
        }

        .prodNr, .prodPrice {
            width: 20%;
        }

    td.prodPrice {
        @include toSizeSM() {
            display: block !important;
        }
    }
}

/* header mobile */
.header-main {
    width: 100%;
    position: relative;
    height: 40px;

    @include fromSizeSM() {
        height: 75px;
    }
}

/* TODO: RS2016 */
.top-menu,
.login-container {
    display: none;

    @include fromSizeSM() {
        display: block;
    }
}

/*Logo*/
a.header-logo {
    display: block;
    z-index: 1;
    position: absolute;
    max-width: 50%;
    top: 4px;
    left: 4px;

    @include fromSizeSM() {
        max-width: none;
        padding: 0;
        display: inline;
        top: 55px;
        left: auto;
    }
}

.body-info-dep a.header-logo {
    top: 3px;
}

.m-logo-top a.header-logo {
    @include toSizeSM {
        display: inline-block;
        width: 100%;
    }
}

.HeaderLogo {
    max-width: 100%;
    max-height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    float: left;

    @include fromSizeSM() {
        max-height: 50px;
    }

    @include fromSizeMD() {
        max-width: none;
        max-height: none;
    }
}


/*Free shipping counter*/
.free-shipping-counter {
    position: static;
    width: 100%;
    color: white;
    font-family: $menuFont;
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    height: 29px;
    text-align: center;
    letter-spacing: 1px;
    line-height: 26px;
    overflow: hidden;

    @include fromSizeSM() {
        letter-spacing: 3px;
        font-size: 18px;
        line-height: 26px;
    }

    p {
        margin: 0;
        background: inline('images/shipping.png');
        background-repeat: no-repeat;
        width: auto;
        display: inline-block;
        padding-left: 35px;
        background-position: 0 4px;
        padding-top: 1px;
    }

    p > span {
        font-weight: bold;
    }
}


/*Mobile top menu*/
.container-top-menu {
    display: inline-block;
    cursor: pointer;
    padding: 9px 13px;
    position: absolute;
    right: 0;
    z-index: 99;

    @include fromSizeSM() {
        display: none;
    }
}

.bar1, .bar2, .bar3 {
    width: 27px;
    height: 2px;
    margin: 4px 0;
    transition: 0.4s;
    border: 2px solid #555;
    border-radius: 2px;
}

.container-top-menu.icon-ellipsis-vert {
    font-size: 23px;
    padding: 6px 11px;
    color: #555;
}

.body-menu-center {
    .bar1, .bar2, .bar3 {
        display: none;
    }
}

/* Rotate first bar */
.change .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}
/* Rotate last bar */
.change .bar3 {
    transform: rotate(45deg) translate(-5px, -6px);
}

/* Fade out the second bar */
.change .bar2 {
    opacity: 0;
}


/*Search*/
.HeaderSearch {
    background: #fff;
    z-index: 47;

    @include toSizeSM {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 40px;
        overflow: hidden;
        box-sizing: border-box;
        z-index: 99999;
        padding-top: 2px;
    }

    @include fromSizeSM() {
        display: block;
        float: left;
        padding: 0;
        position: absolute;
        top: 34px;
        left: calc(50% - 140px);
        background: initial;
    }

    @include fromSizeLG() {
        left: calc(50% - 150px);
    }
}

.fav-body .HeaderSearch {
    left: calc(50% - 140px);

    @include fromSizeSM() {
        left: calc(50% - 140px);
    }

    @include fromSizeMD() {
        left: calc(50% - 188px);
    }

    @include fromSizeLG() {
        left: calc(50% - 150px);
    }

    .show-search {
        left: 0;
    }
}

.HeaderSearch.show-search {
    @include toSizeSM() {
        width: 100%;
        left: 0;
    }
}

.publish-mode .HeaderSearch table {
    background: white;
}

.HeaderSearch table {
    border: none;
    border-collapse: separate;
    width: 100%;

    @include fromSizeSM() {
        float: left;
        border: 1px solid #d7d9d9;
        border-radius: 4px;
        padding: 4px 5px 4px 10px;
    }
}

.HeaderSearch {

    .main-search-a {
        @include toSizeSM() {
            float: right;
            width: 80%;
            padding-top: 5px;
        }

        @include fromSizeSM() {
            padding: 0 0 0 10px;
        }
    }

    .main-search-b {

        @include toSizeSM() {
            float: left;
            padding: 0;
            width: 20%;
        }

        @include fromSizeSM() {
            padding: 0 0 0 10px;
        }
    }
}


.TextBoxSearchDisable,
.TextBoxSearchEnable {
    height: 30px;
    border: none;
    background: transparent;

    &:focus {
        outline: none;
    }

    @include fromSizeSM() {
        width: 200px;
    }

    @include fromSizeLG() {
        width: 250px;
    }
}

.fav-body .TextBoxSearchDisable,
.fav-body .TextBoxSearchEnable {

    @include fromSizeSM() {
        width: 130px;
    }

    @include fromSizeMD() {
        width: 180px;
    }

    @include fromSizeLG() {
        width: 250px;
    }
}

#InstSearchDiv {
    @include toSizeSM() {
        position: relative !important;
        top: 44px !important;
        left: 0 !important;
        width: 100% !important;
        height: auto !important;
    }
}

.HeaderSearch .SearchButton {
    @include fromSizeSM() {
        background-color: transparent;
        background-image: inline('images/magnify.png');
        background-repeat: no-repeat;
        background-position: center;
        background-color: #c1c2c0;
        border: none;
        font-size: 0;
        color: transparent;
        height: 40px;
        width: 40px;
        float: none;
        border-radius: 20px;
        margin: 0;
        padding: 0;
    }

    &:focus {
        outline: none;
    }
}

.search-btn-text {
    display: none;

    @include fromSizeSM {
        display: inline;
    }
}

.search-btn {
    width: 45px;
    height: 45px;
    font-size: 31px;
    color: #434244;
    margin-top: -4px;

    @include fromSizeSM {
        display: inline;
    }
}

/* Instant search */
#CloseSearchButton {
    font-size: 25px;
    color: #434244;

    @include toSizeSM {
        position: absolute;
        z-index: 999;
        top: 0;
        float: left;
        right: 0;
        padding: 4px 40px 0px 10px;
        width: 45px;
        height: 45px;
        display: block !important;
    }

    @include fromSizeSM {
        position: absolute;
        top: 7px;
        display: none;
        float: none;
        right: 35px;
        z-index: 9999999;
        padding: 0px;
        background-color: #fff;
        width: 35px;
        height: 35px;
        padding-right: 10px;
        margin-right: -25px;
    }
}

.bold {
    font-weight: bold;
}

#InstSearchDiv .InstantSearch {
    z-index: 5;
    background-color: #ffffff;
    padding: 0px;
    border-radius: 0;
    box-shadow: 2px 2px 4px rgba(0,0,0,.6);
}

#InstSearchDiv .InstantSearch {
    @include fromSizeMD() {
        margin-top: 10px;
    }
}

#InstSearchDiv .InstantSearch {
    @include toSizeSM() {
        grid-template-columns: 1fr;
        grid-gap: 0px;
    }

    box-shadow: 0px 100vh 4px 100vh rgba(0,0,0,.6);
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    grid-gap: 20px;
}

.InstantSearch table {
    border: none;
    width: 100%;
    padding: 3px 3px 3px 3px;
    border-radius: 0;

    @include toSizeSM() {
        tr > td:nth-child(4) {
            display: none;
        }
    }
}

.InstantSearch .resultcolumn {
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    padding: 0px 0px 10px 20px;
    background-color: #fafafa;
    border-right: 1px #e0e0e0 solid;
}

.InstantSearch .instant-search-preview-column {
    @include toSizeSM() {
        display: none;
    }

    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    padding-right: 20px;
    background: white;
    max-width: 690px;
}

.InstantSearch .d4-instant-search-products tr {
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
}

.InstantSearch .d4-instant-search-products tr.active,
.InstantSearch .d4-instant-search-products tr.active:hover {
    background: white;
    border-top: 1.5px #e0e0e0 solid;
    border-bottom: 1px #e0e0e0 solid;
    border-left: 1px #e0e0e0 solid;
    /*border-right: 1px white solid;*/
}

.InstantSearch .d4-instant-search-products tr.active {
    box-shadow: 2px 0px 0px white;
    z-index: 5;
}

.InstantSearch .d4-instant-search-alternatives,
.InstantSearch .d4-instant-search-preview,
.InstantSearch .d4-instant-search-accessories {
}

.InstantSearch .d4-instant-search-products .d4-instant-search-divider {
    margin-right: 20px;
}

.InstantSearch .d4-instant-search-cat,
.InstantSearch .d4-instant-search-brands,
.InstantSearch .d4-instant-search-articles {
    padding-right: 20px;
}



.InstantSearch .d4-instant-search-products table tr {
}

.InstantSearch .d4-instant-search-products table tr:hover {
    background-color: #f8f8f8;
}

.InstantSearch .d4-instant-search-preview {
    margin-top: 20px;
}

.InstantSearch .d4-instant-search-accessories {
}

.InstantSearch .d4-instant-search-alternatives {
}

.InstantSearch .d4-instant-search-cat {
}

.InstantSearch .d4-instant-search-brands {
}

.InstantSearch .d4-instant-search-articles {
}

.InstantSearch .italic {
    font-style: italic;
}

.InstantSearch table td {
    padding: 3px;
}

.InstantSearch .Left {
    width: 60px;
}

.InstantSearch a:link {
    color: Black;
}

.InstantSearch a:visited {
    color: Black;
}

.InstantSearchClose {
    position: absolute;
    vertical-align: top;
    z-index: 7;
}

.InstantSearch .search-key {
    color: #07ADE0;
}

.HeaderSearch .show-inline-placeholder {
    display: none;
}


/*Top menu / Info menu*/
.TopMenuItem {

    @include toSizeSM() {
        display: block;
        padding: 1em;
        color: #333;
        margin: 0;
        font-size: 14px;

        a {
            &:before {
                margin-right: 0.5em;
            }
        }
    }

    @include fromSizeSM() {
        margin: 0;
        display: inline-block;
        padding: 0 10px;

        &:first-child {
            padding-left: 0;
        }
    }
}

.InfoMenu {
    font-size: 14px;
}

.ImageSepImg {
    display: none;
}
/*Department menu*/
.SubMenuArea {
    float: left;
    width: 100%;
    min-height: 50px;
}

.linkBox {
    display: inline-block;
}

.InlineBlockElement {
    margin: 0;
}

.SubMenuBox {
    display: inline-block;
    padding: 15px 15px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

.SubMenuLink {
    font-family: $menuFont;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;

    &:hover {
        text-decoration: underline;
    }
}
/*Language selection flags*/
.LanguageSelection {
    float: right;
    display: inline-block;
    margin-top: 3px;
    margin-right: 10px;
}

.LanguageSelection input {
    opacity: 0.5;
}

.LanguageSelection input:hover {
    opacity: 0.8;
}

.LanguageSelection .active-language {
    opacity: 1;
}

.OneLanguage {
    border: none;
    background: transparent;
    width: 23px;
    height: 21px;
}

.m-logo-top .menu-container {

    @include toSizeSM() {
        margin-top: 93px;
    }
}

.menu-container .container.menu-inner {

    @include toSizeSM() {
        display: none;
    }
}

.SubMenuArea {
    display: none;

    @include fromSizeSM() {
        display: block;
    }
}

.mmenu-push-right,
.c-menu--push-right {
    display: block;
}

#m-search {
    width: 40px;
    height: 40px;
    right: 110px;
    position: absolute;
    font-size: 30px;
    top: 1px;

    @include fromSizeSM() {
        display: none;
    }
}

.fav-header #m-search {
    right: 150px;
}
/* Setting: 
    Demonstrare 2016 :
    Mobile->MobileMenuScreenSize >= "sm"
*/
.mobilemenu-sm,
.mobilemenu-md,
.mobilemenu-lg {
    /* header */
    @include toSizeSM() {
        .m-header-dock .container {
            border-bottom: 4px solid #3D3D3D;
        }
    }

    .SubMenuArea {
        min-height: 40px;

        @include fromSizeSM() {
            min-height: 2px;
        }

        @include fromSizeMD() {
            min-height: 40px;
        }
    }

    .fav-body .HeaderSearch {
        left: 0;
        padding-top: 0;
        margin-top: 44px;
        border-bottom: 2px solid #3d3d3d;

        @include fromSizeSM() {
            top: 23px;
            margin-top: 0;
            border-bottom: none;
            left: calc(50% - 227px);
        }

        @include fromSizeMD() {
            left: calc(50% - 188px);
        }

        @include fromSizeLG() {
            left: calc(50% - 310px);
        }
    }

    .fav-body .TextBoxSearchDisable,
    .fav-body .TextBoxSearchEnable {

        @include fromSizeSM() {
            width: 180px;
        }

        @include fromSizeLG() {
            width: 350px;
        }
    }

    @include toSizeSM() {
        .HeaderSearch {
            height: 42px;
        }

        #InstSearchDiv {
            top: 86px !important;
        }
    }

    @include fromSizeSM() {
        #CloseSearchButton {
            display: none !important;
        }

        .HeaderSearch table {
            border-radius: 2px;
            padding: 0;
            margin-top: 3px;
        }

    }

    .container-top-menu {
        @include fromSizeSM() {
            top: 16px;

            &:before {
                content: "Meny";
                position: absolute;
                margin-left: -44px;
                margin-top: 4px;
            }
        }
    }

    .WebPubElement .buy-favorites {
        border-radius: 0 4px 4px 0;
        border-left: none;
    }

    .WebPubElement .remove-favorites {
        border-radius: 4px 0 0 4px;
        border-right: none;
    }
}

@include toSizeMD {
    .mobilemenu-sm,
    .mobilemenu-md,
    .mobilemenu-lg {
        .container-top-menu {
            display: inline-block;
        }

        .SubMenuArea {
            min-height: 2px;
        }

        a.cart-small-goto-checkout.btn.btn-primary {
            display: none;
        }
    }
}


@include toSizeLG {
    .mobilemenu-md,
    .mobilemenu-lg {
        .container-top-menu {
            display: inline-block;
        }

        .SubMenuArea {
            min-height: 2px;
        }

        a.cart-small-goto-checkout.btn.btn-primary {
            display: none;
        }
    }
}

@include fromSizeLG {
    .mobilemenu-lg {
        .container-top-menu {
            display: inline-block;
        }

        .SubMenuArea {
            min-height: 2px;
        }

        a.cart-small-goto-checkout.btn.btn-primary {
            display: none;
        }
    }
}

.body-checkout .container-top-menu {
    display: none !important;
}
/*  Menu->ShowInfoMenuOnSameLevelAsDepartment2016 */
.nav-avdinfo {
    .navbar {
        min-height: 40px;
    }

    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}


/* Search mobile. Setting: Mobile->AlwaysShowMobileSearchExpanded=true */
@include toSizeSM {

    .search-expanded {

        .header-main {
            height: 84px;
        }


        #InstSearchDiv {
            top: 86px !important;
        }

        #m-search {
            display: none !important;
        }


        #CloseSearchButton {
            display: none !important;
        }

        .HeaderSearch {
            display: block;
            width: calc(100% - 10px);
            position: absolute;
            border: 1px solid #ccc;            
            height: 37px;
            left: 0;
            margin-left: 5px;
            top: 43px;
            padding-top: 0;
        }

        .HeaderSearch .main-search-a {
            float: none;
            width: 90%;
            padding-top: 0;
            padding-left: 15px;
        }

        .HeaderSearch .main-search-b {
            float: none;
            padding: 0;
            width: 20%;
        }

        .TextBoxSearchDisable, .TextBoxSearchEnable {
            width: 100%;
        }
    }
}
/* End - Search mobile */