﻿.form-group.modell-select {
    width: 100%;
}

.modell-register-header {
}

.modell-register-container {
    max-width: 100%;
    width: 100%;
}

.model-register-page {
    .modell-select.selector-count-4 .form-control {
        @include fromSizeSM {
            width: calc(20% - 10px);
            margin-right: 10px;
        }
        display: inline-block;

        &:last-child {
            margin-right: 0px;
        }
    }

    .my-models-toggler {

        .glyphicon-chevron-down {
            transform: rotate(180deg);
            transition: transform 0.3s;
        }

        &.collapsed {

            .glyphicon-chevron-down {
                transform: rotate(0deg);
            }
        }
    }

    span.icon-spinner {
        font-size: 32px;
    }
}



.D4Standard.virt-prod {
    display: block;

    .text-info {
        text-align: left;
    }



    .action-buttons {

        .btn.btn-primary {
            display: inline-block;

            &.delete-model {
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
    }

    &.selected-model {
        .action-buttons {
            text-align: right;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;

            .save-to-mymodels {
            }
        }
    }

    .text-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}

.layout-d4_x1 .modell-register-container {
    select.form-control {
        @include fromSizeSM {
            width: 20%;
            max-width: 20%;
        }

        display: inline-block;
        margin-right: 10px;
    }

    button.form-control {
        width: auto;
        display: inline-block;
        margin-right: 0px;
    }

    select.form-control {
        width: 100%;
        max-width: 100%;
    }
}