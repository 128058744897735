﻿table.gprod {
    width: 100%;
    padding: 0;
    border-spacing: 2px;
    border-width: 0;

    & thead {
        background-color: #434244;
        color: #ffffff;
    }

    & td {
        padding: 5px;
        border: 1px solid #ebeae5;
        font-family: Open sans,sans-serif;
    }

    & th {
        padding: 5px;
        border: 1px solid #ebeae5;
        font-family: Open sans,sans-serif;
        font-weight: normal;
    }
}
