﻿@import 'mixins';
@import 'variables';


/* -----------------------------------------------------------------------------
  MEDIA ELEMENTS
  
----------------------------------------------------------------------------- */
/*img {
  max-width: 100%;
  height: auto;
}*/

/* -----------------------------------------------------------------------------
  WRAPPER OBJECT
  
----------------------------------------------------------------------------- */
.o-wrapper {
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;

    &.notransition {
        transition: transform 0s;
    }
}

/* -----------------------------------------------------------------------------
  CONTAINER OBJECTS
  
----------------------------------------------------------------------------- */
.o-container {
    margin: 0 auto;
    padding: 0 12px;
    max-width: 960px;
}

@media all and (min-width: 480px) {
    .o-container {
        padding: 0 24px;
    }
}

@media all and (min-width: 720px) {
    .o-container {
        padding: 0 48px;
    }
}
/* -----------------------------------------------------------------------------
  HEADER OBJECT
  
----------------------------------------------------------------------------- */
.o-header {
    margin: 0;
    padding: 0;
}

.o-header-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #67b5d1;
}

.o-header-nav__link {
    padding: 12px;
    color: #fff;
}

.o-header-nav__link:hover {
    color: #fff;
    background-color: #3184a1;
}

.o-header__title {
    margin: 24px;
    padding: 0;
    color: #818181;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
}

@media all and (min-width: 480px) {
    .o-header__title {
        margin: 36px;
        font-size: 42px;
    }
}
/* -----------------------------------------------------------------------------
  MAIN OBJECT
  
----------------------------------------------------------------------------- */
.o-main {
    /**/
}

/* -----------------------------------------------------------------------------
  SUB-NAVIGATION OBJECT
  
----------------------------------------------------------------------------- */
.o-sub-nav {
    margin: 0 0 24px 0;
    padding: 0;
}

.o-sub-nav__items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.o-sub-nav__item {
    margin: 0;
    padding: 4px;
}

.o-sub-nav__link {
    display: block;
    margin: 0;
    padding: 4px 24px;
    border: solid 2px #67b5d1;
}

.o-sub-nav__link:hover,
.o-sub-nav__item.active .o-sub-nav__link {
    color: #fff;
    background-color: #67b5d1;
}

@media all and (min-width: 720px) {
    .o-sub-nav {
        margin: 0 0 48px 0;
    }
}
/* -----------------------------------------------------------------------------
  FOOTER OBJECT
  
----------------------------------------------------------------------------- */
.o-footer {
    padding: 12px 0;
    text-align: center;
}

@media all and (min-width: 480px) {
    .o-footer {
        padding: 24px 0;
    }
}

@media all and (min-width: 720px) {
    .o-footer {
        padding: 48px 0;
    }
}
/* -----------------------------------------------------------------------------
  SLIDE AND PUSH MENUS COMPONENT
  
----------------------------------------------------------------------------- */
/**
 * Menu overview.
 */
.c-menu {
    position: fixed;
    z-index: 200;
    background-color: #fff;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;

    &.notransition {
        transition: transform 0s;
    }
}

.mmenu-link,
.c-menu__items,
.m-menu-items {
    list-style: none;
    margin: 0;
    padding: 0;
}

/**
 * Left and right menus
 *
 * Slide and push menus coming in from the left and right inherit a lot of
 * common styles. We'll start each of them off by doing up the common styles
 * for each version, followed by individual styles.
 *
 * The various versions are governed by modifier classes.
 */
/**
 * Common modifiers for left/right menus.
 */
.c-menu--slide-left,
.c-menu--push-right,
.c-menu--push-left,
.c-menu--push-right,
.mmenu-push-right {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

@media all and (min-width: 320px) {
/*@media all and (min-width: 768px) {*/
    .c-menu--slide-left,
    .c-menu--slide-right,
    .c-menu--push-left,
    .c-menu--push-right,
    .mmenu-push-right {
        width: 300px;
    }
}



.c-menu--slide-left .c-menu__item,
.c-menu--slide-right .c-menu__item,
.c-menu--push-left .c-menu__item,
.c-menu--push-right .c-menu__item,
.mmenu-push-right .mmenu-item {
    display: block;
}

.c-menu--slide-left .c-menu__item:first-child,
.c-menu--slide-right .c-menu__item:first-child,
.c-menu--push-left .c-menu__item:first-child,
.c-menu--push-right .c-menu__item:first-child,
.mmenu-push-right .mmenu-item:first-child {
    border-top: none;
}

.c-menu--slide-left .c-menu__item:last-child,
.c-menu--slide-right .c-menu__item:last-child,
.c-menu--push-left .c-menu__item:last-child,
.c-menu--push-right .c-menu__item:last-child,
.mmenu-push-right .mmenu-item:last-child {
    border-bottom: none;
}

.c-menu--slide-left .c-menu__link,
.c-menu--slide-right .c-menu__link,
.c-menu--push-left .c-menu__link,
.c-menu--push-right .c-menu__link,
.mmenu-push-right .mmenu-link {
    display: block;
    padding: 1em;
    color: #333;

    &:before {
        margin-right: 0.5em;
    }
}

.c-menu--slide-left .c-menu__close,
.c-menu--slide-right .c-menu__close,
.c-menu--push-left .c-menu__close,
.c-menu--push-right .c-menu__close,
.mmenu-push-right .mmenu_close {
    padding: 10px;
    position: absolute;
    z-index: 9999;
    right: 0;
    transition: opacity 1s;
    transition-timing-function: ease-in;
}
/**
 * Slide/Push Menu Left.
 */
.c-menu--slide-left,
.c-menu--push-left {
    top: 0;
    left: 0;
    transform: translate(-100%, 0);    
}



@media all and (min-width: 320px) {
/*@media all and (min-width: 768px) {*/
    .c-menu--slide-left,
    .c-menu--push-left {
        transform: translate(-300px, 0);        
    }
}

.c-menu--slide-left.is-active,
.c-menu--push-left.is-active {
    transform: translate(0, 0);
}
/**
 * Slide/Push Menu Right.
 */
.c-menu--slide-right,
.c-menu--push-right,
.mmenu-push-right {
    top: 0;
    right: 0;
    transform: translate(100%, 0);
}

@media all and (min-width: 320px) {
/*@media all and (min-width: 768px) {*/
    .c-menu--slide-right,
    .c-menu--push-right,
    .mmenu-push-right {
        transform: translate(300px, 0);
    }
}

.c-menu--slide-right.is-active,
.c-menu--push-right.is-active,
.mmenu-push-right.is-active {
    transform: translate(0, 0);
}
/**
 * Top and bottom menus
 *
 * Slide and push menus coming in from the top and bottom inherit a lot of
 * common styles. We'll start each of them off by doing up the common styles
 * for each version, followed by individual styles.
 *
 * The various versions are governed by modifier classes.
/**
 * Common modifiers for top/bottom menus
 */
.c-menu--slide-top,
.c-menu--slide-bottom,
.c-menu--push-top,
.c-menu--push-bottom {
    vertical-align: middle;
    width: 100%;
    height: 60px;
    text-align: center;
    overflow-x: scroll;
}

.c-menu--slide-top .c-menu__items,
.c-menu--slide-bottom .c-menu__items,
.c-menu--push-top .c-menu__items,
.c-menu--push-bottom .c-menu__items {
    display: inline-block;
    text-align: center;
}

.c-menu--slide-top .c-menu__item,
.c-menu--slide-bottom .c-menu__item,
.c-menu--push-top .c-menu__item,
.c-menu--push-bottom .c-menu__item {
    display: inline-block;
    line-height: 60px;
}

.c-menu--slide-top .c-menu__link,
.c-menu--slide-bottom .c-menu__link,
.c-menu--push-top .c-menu__link,
.c-menu--push-bottom .c-menu__link {
    display: block;
    padding: 0 4px;
    color: #fff;
}

.c-menu--slide-top .c-menu__close,
.c-menu--slide-bottom .c-menu__close,
.c-menu--push-top .c-menu__close,
.c-menu--push-bottom .c-menu__close {
    display: inline-block;
    margin-right: 12px;
    padding: 0 24px;
    height: 60px;
    line-height: 60px;
}
/**
 * Slide/Push Menu Top.
 */
.c-menu--slide-top,
.c-menu--push-top {
    top: 0;
    left: 0;
    transform: translateY(-60px);
}

.c-menu--slide-top.is-active,
.c-menu--push-top.is-active {
    transform: translateY(0);
}
/**
 * Slide/Push Menu Bottom.
 */
.c-menu--slide-bottom,
.c-menu--push-bottom {
    bottom: 0;
    left: 0;
    transform: translateY(60px);
}

.c-menu--slide-bottom.is-active,
.c-menu--push-bottom.is-active {
    transform: translateY(0);
}
/**
 * Wrapper states.
 *
 * Various wrapper states occur depending on if a menu is pushing into view, in
 * which case, the wrapper has to be pushed by the respective distance.
 */
.o-wrapper.has-push-left {
    transform: translate(100%, 0);
}

@media all and (min-width: 320px) {
/*@media all and (min-width: 768px) {*/
    .o-wrapper.has-push-left {
        transform: translate(300px, 0);
    }
}

.o-wrapper.has-push-right {
    transform: translate(-100%, 0);
}

@media all and (min-width: 320px) {
/*@media all and (min-width: 768px) {*/
    .o-wrapper.has-push-right {
        transform: translate(-300px, 0);
    }
}

.o-wrapper.has-push-top {
    transform: translateY(60px);
}

.o-wrapper.has-push-bottom {
    transform: translateY(-60px);
}
/**
 * Body states.
 *
 * When a menu is active, we want to hide the overflows on the body to prevent
 * awkward document scrolling.
 */
body.has-active-menu {
    overflow: hidden;
}
/**
 * Close button resets.
 */
.c-menu__close,
.mmenu_close {
    color: #555;
    background: transparent;
    font-size: 16px;
    border: none;
    box-shadow: none;
    appearance: none;
    cursor: pointer;
}

.c-menu__close:focus,
.mmenu_close:focus {
    outline: none;
}
/* -----------------------------------------------------------------------------
  MASK COMPONENT
  
----------------------------------------------------------------------------- */
.c-mask,
.m-mask {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
}

.c-mask.is-active,
.m-mask.is-active {
    width: 100%;
    height: 100%;
    opacity: 0.7; /* opacity: 0.3; */
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &.notransition {
        transition: opacity 0s;
    }
}


@media all and (max-width: 660px) {
    #carbonads {
        display: none;
    }
}
