﻿@import 'mixins';

.santander-calculator {

	.GeMoneyKalkulatorBorder {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 10px;
		border: solid 1px red;
		padding: 5px;
        overflow: hidden;
	}

    .santanderkalkulator-logo-row {
        float: left;
        margin-right: 15px;
        margin-bottom: 5px;

        @include toSizeLG {
            width: 100%;
            text-align: center;
        }
    }

    .santanderkalkulator-row {
        float: left;

        @include toSizeLG {
            clear: left;
            margin-left: 5px;
        }
    }

	.santanderkalkulator-headerlabel {
		font-weight: bold;

        @include toSizeLG {
            margin-left: 5px;
        }
	}

    .santanderkalkulator-label {
        min-width: 130px;
        display: inline-block;
        line-height: 25px;
    }

	.santanderkalkulator-link {
		text-decoration: underline;
        line-height: 25px;
	}

	a {
		color: black;
	}
}

.svea-kalkulator {
    padding-bottom: 15px;
}

.svea-kalkulator-svea-logo {
    width: 70px;
    margin-right: 10px;
}

.svea-kalkulator-label {
    font-weight: bold;
    font-size: larger;
}

.kco-reload-cart-txt {
    text-align: center;
    padding-top: 20px;
    font-size: 16px;
}

.vipps-express-checkout-info-container {

    button.btn.btn-primary.buy-button-vipps-express-checkout {
        background-image: url(/css/images/vipps_ec.svg);
        background-color: transparent;
        background-repeat: no-repeat;
        margin-bottom: 10px;
        background-size: contain;
        height: 42px;
    }
}

.vipps-express-checkout-redirect {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
}

.nets-easy-process-checkout {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
}
