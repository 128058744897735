﻿/* Required styles; changing these may break the menu */
@import 'mixins'; 

/*@include toSizeSM() {*/


    .slinky-menu {
        /*overflow: hidden; Fix: Removed */
        transform: translateZ(0);
        transition: all 300ms ease;
        min-height: 48px;
        background-color: #fff;
    }

    .slinky-menu > ul {
        left: 0;
        position: relative;
        transform: translateZ(0);
        transition: all 300ms ease;
    }

    .slinky-menu ul,
    .slinky-menu li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .slinky-menu ul {
        width: 100%;
    }

.slinky-menu a {
    display: inline-block;
    width: 100%;
    height: 48px;
}

    .slinky-menu li ul {
        display: none;
        left: 100%;
        position: absolute;
        top: 0;
    }

    .slinky-menu .header {
        position: relative;
        text-align: right;
    }

    .slinky-menu h2 ~ a.back {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        text-align: left;
        padding: 11px 14px;
        /*max-width: 35%;*/
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;       
    }

    /* Default theme */

    .slinky-menu .header,
    .slinky-menu .mmenu-header {
        background: #f9f9f9;

        a.header-main {    
            @include fromSizeSM() {
                height: 40px;
            }
        }

    }

    .slinky-menu {

        h2 {
            color: #555;
            padding: 11px 20px 11px 0px;
            margin: 0;
            font-size: 14px;
            /*text-align: center;*/
            line-height: 20px;

            text-align: right;
            width: 70%;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;  
            font-weight:bold;          
        }

        a {
            border: none;
            color: #333;
            padding: 1em;
            text-decoration: none;
        }
    }

    .slinky-menu a.header-main.next {
        height: auto;
        padding: 10px 16px;
    }

    .slinky-menu a.next:after {
        content: '\276f';
        padding: 0 1em 1em 1em;
        float: right;
    }

    .slinky-menu a.next .icon-menu-1{        
        font-size: 21px;
        color: #555;
         vertical-align: sub; 
        float: right;
        margin-top: -6px;
    }

    .slinky-menu a.use-menu-icon.next {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        text-align: left;
        /*background-color: #f9f9f9;*/
        &:after{
            content: '';
            padding:0;
        }
        
     }

    .slinky-menu a.back:before {
        content: '\276e';
        margin-right: 1em;
        float: left;
    }


    .c-menu {

        .m-menu-items a:before,
        .c-menu__items a:before,
        .TopMenuItem a:before {
            content: "";
            display: inline-block;
            font: normal normal normal 14px/1 "multicase-icons";
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 20px;
            color: #555;
            min-width: 30px;
            text-align: center;
        }

        .m-menu-items,
        .c-menu__items {
            .m-login a:before {
                content: "\e814";
            }

            .m-logout a:before {
                content: "\e815";
            }

            .m-regi a:before {
                content: "\f234";
            }
        }

        .TopMenuItem,
        .mmenu-item,
        .c-menu__item {
            a.InfoMenu[href*="kundesenter"]:before {
                content: "\f129";
            }

            a.InfoMenu[href*="sitemap"]:before {
                content: "\f0e8";
            }

            a.InfoMenu[href*="kontaktoss"]:before {
                content: "\e80d";
            }
        }
    }

    .c-menu.mmenu-is-active {

        .m-menu-items,
        .c-menu__items,
        .TopMenu,
        .mmenu_close,
        .c-menu__close {
            visibility:hidden;
            opacity: 0;
            transition: opacity 0.3s;
        }
    }
/*}*/
